import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const { REACT_APP_API_URL, REACT_APP_ENV } = process.env;

const host = window.location.host;




const getendPoints = (
    webHost,
    appEnv,
    appApiUrl,
) => {
    if (appEnv === 'local' || !appEnv) {  
        return appApiUrl;  
    } else {  
        return 'https://' + webHost?.replace('checkin', 'api');  
    } 
};

const endpoint = getendPoints(host, REACT_APP_ENV, REACT_APP_API_URL);


const emptySplitApi = createApi({
    reducerPath: "api",
    tagTypes: [
        'Checkin'
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: `${endpoint}`,
        credentials: "include",
        prepareHeaders: (headers, { getState }) => {
            headers.set("Content-Type", "application/json");
            return headers;
        },
    }),
    endpoints: () => ({}),
});

export default emptySplitApi;
